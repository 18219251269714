<template>
  <button :class="['badge', badgeColor]">
    <slot>{{ name }}</slot>
  </button>
</template>

<script lang="ts">
const cssClass: Record<string, string> = {
  acent: 'badge--acent',
  white: 'badge--white',
  'acent-dark': 'badge--acent-dark',
  'acent-dark-outline': 'badge--acent-dark--outline',
  'secondary-dark': 'badge--secondary-dark',
  secondary: 'badge--secondary',
  'black-friday': 'badge--black-friday',
  christmas: 'badge--christmas',
  valentine: 'badge--valentine',
}

export default defineNuxtComponent({
  props: {
    name: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
  setup(props) {
    const badgeColor = cssClass[props.color]

    return {
      badgeColor,
    }
  },
})
</script>

<style lang="scss">
.badge {
  @apply rounded-full;
  @apply px-2;
  @apply py-1;
  @apply text-spl-dark;
  @apply font-bold;
  @apply font-sans;
  @apply bg-white;
  @apply text-base;

  @screen md {
    @apply text-base;
    @apply py-1;
  }

  &--acent {
    @apply bg-spl-acent;
  }

  &--acent-dark {
    @apply bg-spl-acent-dark;
    @apply text-white;
  }

  &--acent-dark--outline {
    @apply bg-white;
    @apply border border-spl-acent-dark;
    @apply text-spl-acent-dark;
  }

  &--secondary-dark {
    @apply bg-spl-secondary-dark;
    @apply text-spl-dark;
  }

  &--secondary {
    @apply bg-spl-secondary;
    @apply text-gray-800;
  }

  &--white {
    @apply border border-spl-gray-4;
    @apply bg-white;
    @apply font-normal;
  }

  &--black-friday {
    @apply bg-spl-dark;
    @apply text-spl-gray-1;
  }

  &--christmas {
    background: #c1a520;
    color: white;
    border: 2px solid white;
  }

  &--valentine {
    @apply bg-spl-rose;
    @apply border border-spl-red;
    @apply text-white;
  }
}
</style>
